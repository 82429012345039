// cambiare in true per avere i titoli colorati
var titoli_colorati = true;

var spriteImages;

$(document).ready(function () {
   "use strict";

    function afterHeader (el) {
        $(el).closest('section').find('.overline').addClass('overline-visible');
    }

    ScrollReveal().reveal('.simplescroll');
    ScrollReveal().reveal('.revealheader', { distance: '150px', origin: 'bottom', afterReveal: afterHeader});
    ScrollReveal().reveal('.revealtext', { distance: '50px', origin: 'right' });

    setTimeout(function () {
        $('.first-section').addClass('overline-visible');
    }, 300);

    $('#menu-button').click(function(){
        $(this).toggleClass('open');
        $('#menu-container').toggleClass('open');
    });

    load_liquid_home();

    $(window).scroll(function () {

        if($('.cookie-acceptance').css('display') != 'none') {
            $.post("cookie_accepted.php", function (data) {
                $('.cookie-acceptance').slideUp();
            });
        }

        if($(window).width() > 480) {
            if (!$(window).scrollTop()) {
                $('.main-menu-label').fadeOut();
                $('#header-top').removeClass('sticky');
            }

            if ($(window).scrollTop() >= $(document).find('.showMenuLabel').offset().top) {
                $('.main-menu-label').fadeIn();
            }
        }

        var stickyHeight;
        if($(window).width() > 768) {
            stickyHeight = $('#header-top').outerHeight();
        } else {
            stickyHeight = 30;
        }
        if($(window).scrollTop() > stickyHeight) {
            $('#header-top').addClass('sticky');
        }

/*        if ($(document).height() <= ($(window).height() + $(window).scrollTop())) {
            ScrollReveal().animate();
        }*/
    });

    $(window).on('resize', function () {
        if($(window).width() < 480) {
            $('#menu-container').css('right', -$(window).width()+'px').width($(window).width());
        }
    });

    var urllocation;

    $('#menu-container a').on('click', function (ev) {
        ev.preventDefault();

        urllocation = $(this).attr('href');

        go_to_url($(this).attr('href'));

        $('#menu-button').trigger('click');

    });

    $('.cookie-acceptance button').on('click', function () {
       $.post("../cookie_accepted.php", function (data) {
           $('.cookie-acceptance').slideUp();
       });
    });

    $('#logo a').on('click', function (ev) {
        ev.preventDefault();

        urllocation = $(this).attr('href');

        go_to_url($(this).attr('href'));

    });

    if($(window).width() < 480) {
        $('#menu-container').css('right', -$(window).width()+'px').width($(window).width());
    }

    function go_to_url(url) {
        $('#page-container').hide();
        $('#page-container').empty();
        urllocation = url;
        $('#page-container').load(url+'.php', function () {

            if(url == 'home') {
                window.history.pushState(urllocation, null, "/rd/color/");
            } else {
                window.history.pushState(urllocation, urllocation, urllocation);
            }

            $('#page-container').show();

            setTimeout(function () {
                $('.first-section').addClass('overline-visible');
            }, 300);

            fill_header();

            if($('.home').length) {
                load_liquid_home();
            }

            ScrollReveal().sync();
        });
    }

    // Fasce orarie
    var palette = [ ['#EB5E8D', '#A65F9D', '#9591C3', '#EF7062'],
                    ['#4e95d0', '#5bc7d2', '#70ceea', '#c17d67'],
                    ['#7B7EB5', '#403458', '#812A6E', '#1C2A51'] ];

    var fasce_orarie = [5, 14, 22];

    var now = new Date();
    var h = now.getHours();

    var hour_index;
    var fill_color_index = 0;

    fill_header();

    function fill_header() {

        // no color
        if(!titoli_colorati) {
            return;
        }

        fill_color_index = 0;

        for(hour_index = fasce_orarie.length - 1; hour_index > 0; hour_index--) {
            if( h >= fasce_orarie[hour_index] ) {
                break;
            } else {
                if(!h) {
                    h = fasce_orarie.length - 1;
                }
            }
        }

        $('.color-title').each( function () {
            $(this).css('color', palette[hour_index][fill_color_index]);
            //$(this).closest('section').find('.overline').parent().css('color', palette[hour_index][fill_color_index]);
            fill_color_index++;
            fill_color_index %= (palette.length + 1);
        });

    }

    function load_liquid_home() {
        imagesLoaded(document.body, () => document.body.classList.remove('loading'));

        spriteImages = document.querySelectorAll('.slide-item__image');
        var spriteImagesSrc = [];
        var texts = [];

        for (var i = 0; i < spriteImages.length; i++) {

            var img = spriteImages[i];

            // Set the texts you want to display to each slide
            // in a sibling element of your image and edit accordingly
            if (img.nextElementSibling) {
                texts.push(img.nextElementSibling.innerHTML);
            } else {
                texts.push('');
            }

            spriteImagesSrc.push(img.getAttribute('src'));
        }

        var initCanvasSlideshow = new CanvasSlideshow({
            //stageWidth: window.innerWidth,
            //stageHeight: window.innerHeight,
            sprites: spriteImagesSrc,
            displacementImage: 'img/dmaps/512x512/clouds.jpg',
            autoPlay: true,
            autoPlaySpeed: [0.3, 0.3],
            displaceScale: [800, 500],
            displaceAutoFit: true,
            displacementCenter: true,
            interactive: true,
            interactionEvent: 'click', // 'click', 'hover', 'both'
            dispatchPointerOver: true // restarts pointerover event after click

        });
    }

});

function change_slide() {
    $('.scene-nav--prev').trigger('click');
}

function scroll_down_to_content() {
    $('html, body').animate({scrollTop: $('section.showMenuLabel').offset().top - 150}, 500);
}

